import isBrowser from "./isBrowser";

export const restoreFromSessionStorage = (sessionStorageKey) => (isBrowser()) ? JSON.parse(sessionStorage?.getItem(sessionStorageKey) ?? '{}') : {};

export const setCreditId = () => {
    let flujo = restoreFromSessionStorage('flujo')

    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('creditApplicationId') && urlParams.get('creditApplicationId') !== flujo.creditApplicationId) {
        const id = urlParams.get('creditApplicationId')
        flujo = {
            ...flujo,
            creditApplicationId: id,
        }
        sessionStorage.setItem('flujo', JSON.stringify(flujo))
    }

    return flujo
}